<template>
  <div class="item-box" @click="handleCloseSelect">
    <div class="h1">准入工厂查询</div>
    <div class="picker">
      <picker :key="0" :is-show="isShow1" :data="approvePlantOrigins" title="巴西" @click.native="handleSelector(1)"
              @tap-item="handleTabItem($event, 0)"/>
      <picker :key="1" :is-show="isShow2" :data="provinceOptions" :is-origins.sync="isOrigins1" title="所在地"
              @click.native="handleSelector(2)" @tap-item="handleTabItem($event, 1)"/>
      <picker :key="2" :is-show="isShow3" :data="approvePlantNos" :is-origins.sync="isOrigins2" title="厂号"
              @click.native="handleSelector(3)" @tap-item="handleTabItem($event, 2)"/>
      <picker :key="3" :is-show="isShow4" :data="typeOptions" :is-width="true" @click.native="handleSelector(4)"
              title="去/带骨" @tap-item="handleTabItem($event, 3)"/>
      <picker :key="4" :is-show="isShow5" :data="storageOptions" :is-width="true" @click.native="handleSelector(5)"
              title="冻/鲜" @tap-item="handleTabItem($event, 4)"/>
      <search @click.native="handleSelector(0)" @tap-item="handleInput"/>
    </div>
    <!--表格部分-->
    <div class="header">
      <div v-for="(item, index) in items" :key="index">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="table-box">
      <div class="table-body">
        <div v-for="(item, index) in list" :key="index" class="item-box">
          <div class="father-item">
            <div class="item">
              <img :src="item.src" style="height: 52px" class="img-fluid">
            </div>
            <div class="item">{{ item.PlantName }}</div>
            <div class="item">{{ item.PlantNo }}</div>
            <div class="item">{{ item.Function }}</div>
            <div class="item">{{ item.ApprovedDate }}</div>
            <div class="item fb">
              <span :class="{ stop: item.PlantState === 2 }"
                    class="plant-state">{{ item.PlantState === 1 ? '正常' : '暂停' }}</span>
            </div>
            <div class="item">
<!--              <img v-if="item.PlantState === 1" style="width: 30px;height: 30px" src="@/assets/image/cn/collection.png" alt="">-->
<!--              <img v-if="item.PlantState === 2" style="width: 30px;height: 30px" src="@/assets/image/cn/collection_red.png" alt="">-->
              <img class="hand" style="width: 30px;height: 30px;margin-left: 10px" src="@/assets/image/cn/email.png" alt="" @click="handleEmail">
            </div>
          </div>
        </div>
        <div v-if="list.length === 0"
             style="width: 100%; height: 500px; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 18px;">
          <img style="width: 198px;height: 211px" src="@/assets/image/cn/nodata.png">
        </div>
      </div>
    </div>
    <common-dialog id="sendEstEmailDialog" message="sendestemail"/>
  </div>
</template>

<script>
import Picker from '@/views/cn/btcest/components/picker'
import Search from '@/views/cn/btcest/components/search'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'
import CommonDialog from '@/components/cn/CommonDialog'

export default {
  mixins: [commonmix],
  name: 'CnBTCEstOutlines',
  components: {
    Picker,
    Search,
    CommonDialog
  },
  data () {
    return {
      items: ['公司', '工厂', '厂号', '准入功能', '获批时间', '工厂状态', '工厂直通车'],
      list: [],
      max: 50,
      xData: [],
      yData: [],
      tipMsg: '',
      legendVal: [],
      countryVal: '巴西',
      plantnoVal: '全部',
      provinceVal: '全部',
      typeVal: '全部',
      storageVal: '全部',
      indexQuery: {
        PageNum: 1,
        PageSize: 20
      },
      approvePlantOrigins: [],
      approvePlantNos: [],
      provinceOptions: [],
      typeOptions: [
        {
          value: '全部',
          label: '全部'
        },
        {
          value: '去骨',
          label: '去骨'
        },
        {
          value: '带骨',
          label: '带骨'
        }
      ],
      storageOptions: [
        {
          value: '全部',
          label: '全部'
        },
        {
          value: '冰鲜',
          label: '冰鲜'
        },
        {
          value: '冷冻',
          label: '冷冻'
        }
      ],
      rotate: false,
      currentSection: '',
      searchContent: '',
      isFirstView: true,
      viewNumber: 0,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      closeSelect: false,
      isOrigins1: false,
      isOrigins2: false
    }
  },
  watch: {
    closeSelect (val) {
      if (val) {
        this.isShow1 = false
        this.isShow2 = false
        this.isShow3 = false
        this.isShow4 = false
        this.isShow5 = false
        this.closeSelect = false
      }
    }
  },
  computed: {
    userid () {
      return getLocalStore().UserID
    }
  },
  mounted () {
    this.getApprovePlantOrigins()
    this.getFrontApprovePlantNos()
    this.getApprovePlantProvince()
    this.getList()
    var _self = this
    if (document.getElementsByClassName('table-body')[0]) {
      document.getElementsByClassName('table-body')[0].addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop === this.clientHeight) {
          _self.getList(true)
        }
      })
    }
  },
  methods: {
    handleTabItem (item, type) {
      switch (type) {
        case 0 :
          this.countryVal = item.value
          this.plantnoVal = '全部'
          this.provinceVal = '全部'
          this.getApprovePlantProvince()
          this.getFrontApprovePlantNos()
          this.searchContent = this.countryVal
          this.currentSection = '_origin'
          this.isOrigins1 = true
          this.isOrigins2 = true
          break
        case 1:
          this.provinceVal = item.value
          this.getFrontApprovePlantNos()
          this.searchContent = this.provinceVal
          this.currentSection = '_location'
          break
        case 2:
          this.plantnoVal = item.value
          this.searchContent = this.plantnoVal
          this.currentSection = '_plantno'
          break
        case 3:
          this.typeVal = item.value
          this.searchContent = this.typeVal
          this.currentSection = '_meat_type'
          break
        case 4:
          this.storageVal = item.value
          this.searchContent = this.storageVal
          this.currentSection = '_storage_mode'
          break
        default:
      }

      this.addSearchInfo()
      this.handleSearch()
    },
    handleInput (item) {
      if (this.$store.getters.token) {
        this.indexQuery.KeyWord = item
        this.handleSearch()
      } else {
        this.openCnLoginModal()
      }
    },
    addSearchInfo () {
      this.$store.dispatch('AddWebPlantSearchInfo', {
        UserID: this.userid,
        SearchCategory: 'Approved Beef Establishments',
        SearchSection: this.currentSection,
        SearchContent: this.searchContent
      })
    },
    getApprovePlantOrigins () {
      this.$store.dispatch('GetWebFrontApprovePlantOrigin', {
        isEn: false
      }).then(() => {
        this.approvePlantOrigins = this.$store.getters.etcPlantOrigins.datas.map((v) => {
          return {
            value: v,
            label: v
          }
        })
      })
    },
    getFrontApprovePlantNos () {
      this.$store.dispatch('GetWebFrontApprovePlantNo', {
        isEn: false,
        Origin: this.countryVal,
        Location: this.provinceVal
      }).then(() => {
        const approvePlantNosMap = this.$store.getters.etcPlantPlantNos.datas.map((v) => {
          return {
            value: v,
            label: v
          }
        })
        approvePlantNosMap.unshift({
          label: '全部',
          value: '全部'
        })
        this.approvePlantNos = approvePlantNosMap
      })
    },
    getApprovePlantProvince () {
      this.$store.dispatch('GetWebFrontApprovePlantProvince', {
        isEn: false,
        Origin: this.countryVal
      }).then(() => {
        const provinceOptionsMap = this.$store.getters.etcPlantProvinces.datas.map((v) => {
          return {
            value: v,
            label: v
          }
        })
        provinceOptionsMap.unshift({
          label: '全部',
          value: '全部'
        })
        this.provinceOptions = provinceOptionsMap
      })
    },
    getList (isMore) {
      if (isMore) {
        this.indexQuery.PageNum += 1
      } else {
        this.indexQuery.PageNum = 1
      }
      this.indexQuery.PlantNo = this.plantnoVal
      this.indexQuery.Origin = this.countryVal
      this.indexQuery.Location = this.provinceVal
      this.indexQuery.MeatType = this.typeVal
      this.indexQuery.StorageMode = this.storageVal
      this.indexQuery.IsDesc = this.rotate
      this.indexQuery.LanguageCode = 'zh-CN'
      this.indexQuery.UserID = getLocalStore().UserID
      this.indexQuery.IsFirst = this.isFirstView
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebFrontApprovePlant', this.indexQuery).then(() => {
        if (isMore) {
          this.list = this.list.concat(this.$store.getters.etcPlantDatas)
        } else {
          this.list = this.$store.getters.etcPlantDatas
        }
        this.getIcon()
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        if (err.ErrorCode === 6004) {
          this.$router.push('/english/charge')
        }
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    handleSearch () {
      this.trial(false)
    },
    handleTrial () {
      this.trial(true)
    },
    trial (isRead) {
      // if (this.$store.getters.token) {
      this.getList()
      // } else {
      //   this.openLoginModal()
      // }
    },
    handleFilter () {
      this.rotate = !this.rotate
      this.getList()
    },
    getIcon () {
      this.list.forEach(item => {
        if (item && item.OriginEn && item.Company) {
          item.src = `${process.env.VUE_APP_STATIC}CnPlantIcon/${
            item.Company.replace(/-/g, '')
              .replace(/&/g, '')
              .replace(/’/g, '')
              .replace(/\s/g, '')
              .replace(/í/g, 'i')
              .replace(/\./g, '')
              .replace(/'/g, '')
              .replace(/ú/g, 'u')
              .replace(/ú/g, 'u')
              .replace(/Í/g, 'i')
              .replace(/“/g, '')
              .replace(/”/g, '')
              .replace(/-/g, '')
              .replace(/ó/g, 'o')
              .replace(/"/g, '')
              .replace(/ē/g, 'e')
              .replace(/ļ/g, 'l')
              .replace(/ā/g, 'a')
              .replace(/,/g, '')
              .toLocaleLowerCase()}@2x.png`
        }
        return null
      })
    },
    handleCloseSelect () {
      this.closeSelect = true
    },
    handleSelector (type) {
      switch (type) {
        case 1:
          this.isShow1 = !this.isShow1
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = false
          break
        case 2:
          this.isShow1 = false
          this.isShow2 = !this.isShow2
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = false
          break
        case 3:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = !this.isShow3
          this.isShow4 = false
          this.isShow5 = false
          break
        case 4:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = !this.isShow4
          this.isShow5 = false
          break
        case 5:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = !this.isShow5
          break
        default:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = false
      }
    },
    handleEmail () {
      if (this.$store.getters.token) {
        this.$bvModal.show('sendEstEmailDialog')
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-width: 170px;
$cell-height: 52px;

.item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h1 {
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0 10px 0;
}

.picker {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: $cell-height;
  border-radius: 7px;
  background: #e6e6e6;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

::-webkit-scrollbar {
  display: none
}

.table-body {
  margin-top: 10px;
  height: 1000px;
  min-width: 1200px;
  overflow-y: scroll;
}

.table-body > .item-box {
  min-height: $cell-height;
}

.table-body > .item-box > .father-item {
  height: $cell-height;
  border-radius: 7px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
  box-shadow: 0 1px 10px 1px #e1e1e1;
}

.table-body > .item-box > .father-item > .item {
  width: $cell-width;
  height: $cell-height;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
}

.trans {
  transform: rotate(180deg)
}

.plant-state {
  min-width: 60px;
  background: #28a947;
  color: #ffffff;
  padding: 5px;
  display: inline-block;
  border-radius: 5px !important;
  font-weight: lighter;
  transform: scale(0.9);
}

.fb {
  font-weight: bold;
}

.stop {
  background: #d13636 !important;
}

</style>
